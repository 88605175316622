/*------------------------------------
  Clients
------------------------------------*/

.u-clients {
  width: 100%;
  max-width: $clients-width;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}