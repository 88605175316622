.delete-svg {
  margin-top: 30px;
  cursor: pointer;
}

.options-container {
  @include make-row();
  background-color: $white;
  @include border-radius($dropzone-filepreview-border-radius);
  padding: $dropzone-filepreview-padding;
  box-shadow: $dropzone-filepreview-shadow;
  position: relative;
  .close-option-button {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
  }
}
.u-dropzone {
  &-has-error {
    box-shadow: $form-error-box-shadow;
    border: 1px solid $form-error-border-color;
  }
}
