/*------------------------------------
  Sticky Positions
------------------------------------*/

.u-header--sticky {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &-top#{$infix},
      &-bottom#{$infix} {
        position: fixed;
      }

      &-top#{$infix} {
        bottom: auto;
        top: 0;
      }

      &-bottom#{$infix} {
        top: auto;
        bottom: 0;

        .u-header__sub-menu {
          top: auto;
          bottom: 100%;

          .u-header__sub-menu {
            top: 0;
            bottom: auto;
          }
        }

        .navbar-nav .u-header__sub-menu {
          @include border-top-radius($header-sub-menu-top-border-radius);
          @include border-bottom-radius(0);
          box-shadow: $header-absolute-bottom-submenu-box-shadow;
        }

        .slideInUp {
          animation-name: stickySlideInUp;
        }
      }
    }
  }
}

// Medium Devices
@include media-breakpoint-between(md, md) {
  .u-header--sticky-bottom-md {
    &:not(.js-header-fix-moment) {
      .navbar-expand-md {
        .hs-mega-menu {
          bottom: 52.8%;
        }
      }
    }
  }
}

// Small to Medium Devices
@include media-breakpoint-between(sm, md) {
  .u-header--sticky-bottom,
  .u-header--sticky-bottom-sm {
    &:not(.js-header-fix-moment) {
      .navbar-expand-md {
        .hs-mega-menu {
          bottom: 52.8%;
        }
      }
    }
  }
}

// Long list of links, can lead to a bug, since the header can not calculate the height when.
// For this reason, max-height is given to avoid header disappearing issues on collapse.
.u-header--sticky-top__inner,
.u-header--sticky-bottom__inner {
  max-height: $header-sticky-top-height;

  &.mCS_destroyed {
    position: static !important;
  }

  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
}

/* Disable box-shadow on mobile device */
.u-header--sticky {
  &-bottom {
    &:not(.js-header-fix-moment) {
      [class*="navbar-expand"] {
        .navbar-nav {
          .u-header__sub-menu {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@keyframes stickySlideInUp {
  from {
    transform: translate3d(0, -10px, 0);
  }
}