@media (min-width: 992px) {
  .row-inverted-mobile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 992px) {
  .row-inverted-mobile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-right: -15px;
    margin-left: -15px;
  }
}
