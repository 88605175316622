/*------------------------------------
  Got to Section - Wave
------------------------------------*/

.u-go-to-wave {
  width: $go-to-wave-width;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(xs) {
	 width: 10rem;
  }

	&__icon {
		transform: translateY(-50%);
	}
}