/*------------------------------------
  Tables
------------------------------------*/

.table-heighlighted {
  thead th {
    border-bottom-color: $table-border-highlighted-color;
    border-top: none;
  }

  tfoot td {
    border-top: (2 * $table-border-width) solid $table-border-highlighted-color;
  }
}

.table-light-bordered {
  border-color: $table-border-light-color;

  thead th,
  th,
  td {
    border-color: $table-border-light-color;
  }
}
