/*------------------------------------
  Slick Pagination
------------------------------------*/

.u-slick__pagination {
  padding-left: 0;

  &:not(.u-slick__pagination--block) {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  li {
    display: flex;
    pointer-events: all;
    margin: 0 .25rem;
    cursor: pointer;

	  span {
	  	display: inline-block;
	  	width: $slick-pagination-dot-width;
	  	height: $slick-pagination-dot-height;
      box-sizing: border-box;
	    background-color: $slick-pagination-dot-bg-color;
	    border: $slick-pagination-dot-border-width $slick-pagination-dot-border-style $slick-pagination-dot-border-color;
	    @include border-radius($slick-pagination-dot-border-radius);
	    transform: $slick-pagination-dot-transform;
	    transition: $slick-pagination-dot-transition;
	  }

    &.slick-active {
      span {
        background-color: $slick-pagination-dot-bg-active-color;
		    border-color: $slick-pagination-dot-border-active-color;
	    	transform: $slick-pagination-dot-transform-active;
      }
    }
  }
}

/* White Version */
.u-slick__pagination--white {
  li {
    span {
      background-color: $slick-pagination-white-dot-bg-color;
    }

    &.slick-active {
      span {
        border-color: $slick-pagination-white-dot-border-color;
      }
    }
  }
}

/* Block */
.u-slick__pagination--block {
  li {
    display: block;
  }
}

/* Vertical Center Alignment */
.u-slick__pagination-centered--y {
  @include content-centered(false, true);
}

// Large Devices
@include media-breakpoint-up(lg) {
  /* Vertical Option */
  .u-slick__pagination--vertical-lg {
    li {
      display: block;
      margin: .25rem 0;
    }
  }

  /* Vertical Option Positions */
  .u-slick__pagination--vertical-pos-v1-lg {
  	position: absolute;
  	top: 50%;
  	left: -15.7%;
  	transform: translateY(-50%);
  }
}