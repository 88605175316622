/*------------------------------------
  DatePicker
------------------------------------*/

.u-datepicker {
  position: relative;

  .flatpickr-calendar {
    top: 3.75rem !important;
    left: 0 !important;
    box-shadow: $box-shadow;

    &::before,
    &::after {
      display: none;
    }
  }

  .flatpickr-months {
    position: relative;
    background-color: $primary;
    @include border-top-radius($border-radius);
    padding: .75rem;

    .cur-month,
    .cur-year {
      color: $white;
    }
  }

  .flatpickr-current-month,
  .flatpickr-prev-month,
  .flatpickr-next-month {
    padding: 0;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: 50%;
    transform: translate(0, -50%);

    &.flatpickr-prev-month {
      left: .75rem;
    }

    &.flatpickr-next-month {
      right: .75rem;
    }

    svg {
      fill: $white-color-70;
    }

    &:hover {
      svg {
        fill: $white;
      }
    }
  }

  .flatpickr-weekday {
    color: $dark;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
  }

  .flatpickr-day {
    color: $dark;
    border-color: transparent;

    &:focus,
    &:hover {
      color: $primary;
      background-color: rgba($primary, .1);
    }

    &.inRange {
      background-color: $gray-300;
      box-shadow: -.3125rem 0 0 $gray-300, .3125rem 0 0 $gray-300;
    }

    &.today {
      color: $white;
      background-color: $primary;
    }

    &.prevMonthDay,
    &.nextMonthDay {
      color: $gray-300;

      &:focus,
      &:hover {
        color: $gray-500;
        background-color: $gray-300;
      }
    }

    &.disabled {
      color: $gray-300;

      &:hover {
        color: $gray-500;
        background-color: $gray-300;
      }
    }

    &.selected {
      color: $primary;
      background-color: transparent;
      border-color: $primary;

      &:focus,
      &:hover {
        &.prevMonthDay,
        &.nextMonthDay {
          color: $primary;
          background-color: transparent;
        }
      }

      &.startRange,
      &.endRange {
        color: $white;
        background-color: $primary;
      }
    }
  }

  .numInputWrapper {
    span.arrowUp,
    span.arrowDown {
      display: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

/* Positions */
.u-datepicker {
  &--top {
    .flatpickr-calendar {
      top: auto !important;
      bottom: 3.5rem !important;
    }
  }
}