/*------------------------------------
  Fileuploader
------------------------------------*/

/* Default Style */
.fileuploader {
  margin-top: 0;
}

/* Front Style */
.u-fileuploader-input {
  display: block;
  cursor: pointer;
  background: $white;
  border: 2px dashed $gray-300;
  @include border-radius($border-radius);
  padding: 2rem 0;
  text-align: center;

  &__icon {
    display: block;
    color: $primary;
    font-size: 2.5rem;
  }
}

/* Dragging State */
.u-fileuploader-input {
  &__inner,
  &.fileuploader-dragging .u-fileuploader-input__icon {
    transition: all $transition-timing $transition-function;
  }

  &.fileuploader-dragging {
    .u-fileuploader-input__inner {
      opacity: .6;
    }

    .u-fileuploader-input__icon {
      transform: translateY(18px);
    }

    .u-fileuploader-input__btn {
      transform: none;
      opacity: 1;
    }
  }
}