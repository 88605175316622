.Modal {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 5%;
  background-color: $body-bg;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
}
