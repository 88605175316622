/*------------------------------------
  Devices v2
------------------------------------*/

.u-devices-v2 {
	position: relative;
	overflow: hidden;

	&__tablet {
		position: absolute;
		left: 0;
		top: 0%;
		z-index: 1;
		width: 34.375rem;
		height: 50.875rem;
		transform: rotate(15deg);
		transform-origin: 90% -20%;

		@include media-breakpoint-down(md) {
			transform-origin: 100% -40%;
		}

		&-svg {
			@include border-radius(1.85rem);
			box-shadow: 0 0 .75rem .5rem rgba($dark, .1);
		}
	}

	&__phone {
		position: absolute;
		right: 0;
		bottom: -25%;
		z-index: 1;
		width: 19.8125rem;
		height: 41.5625rem;
		transform: rotate(-20deg);
		transform-origin: 100% 0;

		&--left-position {
			position: absolute;
			right: auto;
			left: 0;
			transform-origin: 100% 40%;

			@include media-breakpoint-down(lg) {
				transform-origin: 100% 65%;
			}
		}

		&-svg {
			@include border-radius(3rem);
			box-shadow: 0 0 .75rem .5rem rgba($dark, .1);
		}
	}
}