/*------------------------------------
  Background Overlay Gradients
------------------------------------*/

[class*="gradient-overlay-half"] {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		content: "";
	}
}

.gradient-overlay-half-primary-video-v1 {
	&::before {
		z-index: 2;
		@include gradient-x($hs-start-color: rgba($gradient-overlay-half-indigo, .9), $hs-end-color: rgba($gradient-overlay-half-primary-lighter, .85), $deg: 150deg);
	}
}

.gradient-overlay-half-primary-v1 {
	&::before {
		@include gradient-x($hs-start-color: rgba($gradient-overlay-half-indigo, .9), $hs-end-color: rgba($gradient-overlay-half-primary-lighter, .85), $deg: 150deg);
	}
}

.gradient-overlay-half-primary-v2 {
	&::before {
		@include gradient-x($hs-start-color: rgba($gradient-overlay-half-primary-lighter, .85), $hs-end-color: rgba($gradient-overlay-half-indigo, .9), $deg: 30deg);
	}
}

.gradient-overlay-half-primary-v3 {
	&::before {
		@include gradient-x($hs-start-color: $gradient-overlay-half-primary, $hs-end-color: darken($gradient-overlay-half-primary-darker, 10%), $deg: 90deg);
	}
}

.gradient-overlay-half-primary-v4 {
	&::before {
		@include gradient-y($hs-start-color: rgba($gradient-overlay-half-primary, .025), $hs-end-color: $gradient-overlay-half-white, $hs-start-percent: 0%);
	}
}

.gradient-overlay-half-indigo-v1 {
	&::before {
		@include gradient-x($hs-start-color: transparent, $hs-end-color: rgba($gradient-overlay-half-indigo, .1), $hs-start-percent: 50%, $deg: 45deg);
	}
}

.gradient-overlay-half-info-v1 {
	&::before {
		@include gradient-x($hs-start-color: rgba($gradient-overlay-half-primary, .92), $hs-end-color: rgba($gradient-overlay-half-info, .92), $deg: 0deg);
	}
}

.gradient-overlay-half-dark-v1 {
	&::before {
		@include gradient-x($hs-start-color: $gradient-overlay-half-dark, $hs-end-color: transparent, $hs-end-percent: 75%, $deg: 0deg);
	}
}

.gradient-overlay-half-dark-v2 {
	&::before {
		@include gradient-x($hs-start-color: mix(rgba($gradient-overlay-half-primary, .35), $gradient-overlay-half-dark), $hs-end-color: rgba($gradient-overlay-half-gray-700, .3), $deg: 150deg);
	}
}