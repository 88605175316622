/*------------------------------------
  Slick Gutters
------------------------------------*/

/* Gutters X */
.u-slick--gutters-1 {
	margin-left: -.25rem;
	margin-right: -.25rem;

	.slick-slide {
		margin-left: .25rem;
		margin-right: .25rem;
	}
}

.u-slick--gutters-2 {
	margin-left: -.5rem;
	margin-right: -.5rem;

	.slick-slide {
		margin-left: .5rem;
		margin-right: .5rem;
	}
}

.u-slick--gutters-3 {
	margin-left: -.9375rem;
	margin-right: -.9375rem;

	.slick-slide {
		margin-left: .9375rem;
		margin-right: .9375rem;
	}
}