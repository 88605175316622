figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    top,
    rgba(235, 184, 123, 0) 0%,
    rgba(235, 184, 123, 0.8) 75%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 75%
  );
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(0, 80%, 0);
  transform: translate3d(0, 80%, 0);
}
.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

figure.effect-sadie span {
  position: absolute;
  top: 80%;
  left: 0;
  padding: 25px;
  width: 100%;
  color: $white;
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

figure.effect-sadie:hover span {
  color: #fff;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -90px, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -90px, 0);
}

figure.effect-sadie:hover figcaption::before,
figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.dark-overlay {
  top: 0;
  left: 0;
  background: black;
}
.dark-overlay div {
  opacity: 0.8;
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  user-select: none;
  background-color: #333333;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

figure.effect-julia img {
  max-width: none;

  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia span {
  position: relative;
  padding: 0.5em 0;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255, 255, 255, 0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-500, 0, 0);
  transform: translate3d(-500px, 0, 0);
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

// figure img {
//   position: relative;
//   display: block;
//   min-height: 100%;
//   max-width: 100%;
//   opacity: 0.8;
// }

figure figcaption {
  padding: 2em;
  color: #fff;
  font-size: 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
  pointer-events: none;
}

figure figcaption,
figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.premium-card-frame-highlighted {
  box-shadow: $card-frame-primary-hover-box-shadow;
}
