/*------------------------------------
  File Attachment Link
------------------------------------*/

.file-attachment-link {
	position: relative;
  top: .4375rem;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__label {
    color: $file-attachment-link-label-color;
    border-bottom: $file-attachment-link-label-border-width $file-attachment-link-label-border-type $file-attachment-link-label-border-color;
    margin-bottom: 0;
  }

  &:hover {
    .u-file-attachment-link__label {
      color: $file-attachment-link-label-hover-color;
      border-color: $file-attachment-link-label-hover-border-color;
    }
  }
}